<template>
    <div>
        <v-dialog :persistent="revokeLoading" v-if="showRevokeButton" v-model="revokeDialog" max-width="500">
            <template v-slot:activator="{ on: dialog }">
                <v-tooltip left>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            small
                            color="secondary"
                            icon
                            v-on="{ ...tooltip, ...dialog }"
                            v-if="
                                hierarchicalLevel === nuvolosObjectTypes.ORGANIZATION ||
                                hierarchicalLevel === nuvolosObjectTypes.SPACE ||
                                (reason && hierarchicalLevel === nuvolosObjectTypes.INSTANCE && reason.toUpperCase() === 'EXPLICIT') ||
                                (hierarchicalLevel === nuvolosObjectTypes.INSTANCE && !spaceAdmin)
                            ">
                            <v-icon small>clear</v-icon>
                        </v-btn>
                        <v-tooltip v-else-if="hierarchicalLevel === nuvolosObjectTypes.INSTANCE && reason.toUpperCase() !== 'EXPLICIT'" left>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon><v-icon small>clear</v-icon></v-btn>
                            </template>
                            <span>
                                You cannot revoke
                                {{
                                    reason === roleTypes.SPACE_ADMIN
                                        ? ' a space admin'
                                        : reason === roleTypes.ORG_MANAGER
                                        ? ' an organization manager'
                                        : reason + ' role.'
                                }}.
                            </span>
                        </v-tooltip>
                    </template>
                    <span>Revoke User Access</span>
                </v-tooltip>
            </template>
            <v-card>
                <v-card-title class="text-uppercase d-flex align-center">
                    <v-icon left>clear</v-icon>
                    revoke from {{ hierarchicalLevel }}
                </v-card-title>
                <v-card-text>
                    <v-card flat class="grey lighten-3 mb-3">
                        <v-card-text class="text-body-1">
                            <v-container fluid>
                                <v-row no-gutters class="mb-2">
                                    <v-col cols="3">User:</v-col>
                                    <v-col>
                                        <b>{{ userName }}</b>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters v-if="hierarchicalLevel === nuvolosObjectTypes.INSTANCE" class="mb-2">
                                    <v-col cols="3">Instance:</v-col>
                                    <v-col>
                                        <b>{{ instanceName }}</b>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters v-else-if="hierarchicalLevel === nuvolosObjectTypes.SPACE" class="mb-2">
                                    <v-col cols="3">Space:</v-col>
                                    <v-col>
                                        <b>{{ spaceName }}</b>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters v-else-if="hierarchicalLevel === nuvolosObjectTypes.ORGANIZATION" class="mb-2">
                                    <v-col cols="3">Organization:</v-col>
                                    <v-col>
                                        <b>{{ orgName }}</b>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="3">Role:</v-col>
                                    <v-col>
                                        <b>{{ role }}</b>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>

                    <v-alert text color="info">
                        <span v-if="hierarchicalLevel === nuvolosObjectTypes.INSTANCE">
                            By revoking the user instance role, the user will no longer be able to see or work with the instance.
                        </span>
                        <span v-if="hierarchicalLevel === nuvolosObjectTypes.SPACE">
                            By revoking the user space role, the user will lose the administrator rights for this space but will keep the instance-level roles
                            if any.
                        </span>
                        <span v-if="hierarchicalLevel === nuvolosObjectTypes.ORGANIZATION">
                            By revoking the user organization role, the user will no longer be able to see or work with any of the spaces and instances in the
                            organization.
                        </span>
                    </v-alert>
                    <v-checkbox v-if="hierarchicalLevel === nuvolosObjectTypes.SPACE" v-model="revokeSpaceChildren" hide-details color="error">
                        <template v-slot:label>
                            <span class="error--text">Revoke all instance roles for this user.</span>
                        </template>
                    </v-checkbox>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="revokeLoading" color="secondary" class="mr-1" text @click="revokeDialog = false">Cancel</v-btn>
                    <v-btn :loading="revokeLoading" color="error" @click="confirmAction('revokeAccess', ucid)">Revoke</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :persistent="activationLoading"
            v-model="deactivateDialog"
            max-width="500"
            v-if="userInfo.is_sysadmin && isActiveUser && showDeactivateActivateButton">
            <template v-slot:activator="{ on: dialog }">
                <v-tooltip left>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn small color="error" text icon v-on="{ ...tooltip, ...dialog }">
                            <v-icon small>mdi-account-off</v-icon>
                        </v-btn>
                    </template>
                    <span>Deactivate User</span>
                </v-tooltip>
            </template>
            <v-card>
                <v-card-title class="text-uppercase d-flex align-center">
                    <v-icon left>mdi-account-off</v-icon>
                    user deactivation
                </v-card-title>
                <v-divider class="mb-1"></v-divider>
                <v-card-text>
                    Are you sure you want to deactivate the account of the user
                    <span class="font-weight-bold">{{ userName }}</span>
                    ?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="activationLoading" color="secondary" class="mr-1" text @click="deactivateDialog = false">Cancel</v-btn>
                    <v-btn :loading="activationLoading" text color="error" @click="confirmAction('deactivateUser', uid)">deactivate</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            :persistent="activationLoading"
            v-model="activateDialog"
            max-width="500"
            v-if="userInfo.is_sysadmin && !isActiveUser && showDeactivateActivateButton">
            <template v-slot:activator="{ on: dialog }">
                <v-tooltip left>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn small color="success" text icon v-on="{ ...tooltip, ...dialog }">
                            <v-icon small>mdi-account-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Activate User</span>
                </v-tooltip>
            </template>
            <v-card>
                <v-card-title>
                    <div class="title secondary--text text-uppercase d-flex align-center">
                        <v-icon class="mr-1">mdi-account-check</v-icon>
                        user activation
                    </div>
                </v-card-title>
                <v-divider class="mb-1"></v-divider>
                <v-card-text>
                    Are you sure you want to activate the account of the user
                    <span class="font-weight-bold">{{ userName }}</span>
                    ?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="activationLoading" color="secondary" class="mr-1" text @click="activateDialog = false">cancel</v-btn>
                    <v-btn :loading="activationLoading" text color="error" @click="confirmAction('activateUser', uid)">activate</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'

export default {
    name: 'UserActions',
    mixins: [enumsData],
    props: {
        uid: String,
        ucid: String,
        userName: String,
        hierarchicalLevel: String,
        isActiveUser: Boolean,
        role: String,
        reason: {
            type: String,
            default: null
        },
        spaceAdmin: {
            type: Boolean,
            default: null
        },
        showRevokeButton: {
            type: Boolean,
            default: true
        },
        showDeactivateActivateButton: {
            type: Boolean,
            default: true
        },
        instanceName: {
            type: String,
            default: null
        },
        instanceId: {
            type: String,
            default: null
        },
        spaceName: {
            type: String,
            default: null
        },
        orgName: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            revokeDialog: false,
            deactivateDialog: false,
            activateDialog: false,
            revokeLoading: false,
            activationLoading: false,
            revokeSpaceChildren: false
        }
    },
    methods: {
        confirmAction: function (actionType, userId) {
            let apiURL
            let isAsync
            if (actionType === 'revokeAccess') {
                this.revokeLoading = true
                if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.ORGANIZATION) {
                    apiURL = '/orgs/' + this.$route.params.oid + `/remove_conn/${userId}/async`
                    isAsync = true
                } else if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.SPACE && this.revokeSpaceChildren) {
                    apiURL = '/spaces/' + this.$route.params.sid + `/remove_conn/${userId}`
                } else if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.SPACE && !this.revokeSpaceChildren) {
                    apiURL = '/spaces/' + this.$route.params.sid + `/revoke_role_from_conn/${userId}`
                } else if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.INSTANCE) {
                    apiURL = '/instances/' + this.instanceId + `/revoke_role_from_conn/${userId}`
                }
                this.$store.dispatch('showSnackBar', {
                    snackBarText: isAsync ? 'Revoking access, see details on your tasks screen.' : 'Revoking access, please wait....',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
            } else if (actionType === 'activateUser') {
                this.activationLoading = true
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Activating user account, please wait....',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
                apiURL = `/users/${userId}/activate`
            } else if (actionType === 'deactivateUser') {
                this.activationLoading = true
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Deactivating user account, please wait....',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
                apiURL = `/users/${userId}/deactivate`
            }
            actionType === 'revokeAccess'
                ? this.$axios
                      .delete(apiURL)
                      .then(response => {
                          !isAsync &&
                              this.$store.dispatch('showSnackBar', {
                                  snackBarText: 'User access revoked successfully',
                                  snackBarTimeout: 5000,
                                  snackBarIcon: 'check_circle'
                              })
                          if (
                              this.$props.hierarchicalLevel === this.nuvolosObjectTypes.SPACE ||
                              this.$props.hierarchicalLevel === this.nuvolosObjectTypes.INSTANCE
                          ) {
                              this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
                          } else if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.ORGANIZATION) {
                              this.$store.dispatch('orgStore/fetchOrgUsers', this.$route.params.oid)
                          }
                      })
                      .catch(() => {
                          this.$store.dispatch('showSnackBar', {
                              snackBarText: 'An error has occurred',
                              snackBarTimeout: 10000,
                              snackBarIcon: 'error'
                          })
                      })
                      .finally(() => {
                          this.revokeLoading = false
                          this.revokeDialog = false
                      })
                : this.$axios
                      .put(apiURL)
                      .then(response => {
                          this.$store.dispatch('showSnackBar', {
                              snackBarText:
                                  actionType === 'activateUser'
                                      ? 'User account activated successfully!'
                                      : actionType === 'deactivateUser'
                                      ? 'User account deactivated successfully'
                                      : 'Operation finished successfully!',
                              snackBarTimeout: 5000,
                              snackBarIcon: 'check_circle'
                          })
                          this.$store.dispatch('orgStore/fetchOrgUsers', this.$route.params.oid)
                          if (
                              this.$props.hierarchicalLevel === this.nuvolosObjectTypes.SPACE ||
                              this.$props.hierarchicalLevel === this.nuvolosObjectTypes.INSTANCE
                          ) {
                              this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
                              this.$store.dispatch('instanceStore/fetchInstanceUsers', this.$route.params.iid)
                          }
                      })
                      .catch(() => {
                          this.$store.dispatch('showSnackBar', {
                              snackBarText: 'An error has occurred',
                              snackBarTimeout: 10000,
                              snackBarIcon: 'error'
                          })
                      })
                      .finally(() => {
                          this.activationLoading = false
                          this.activateDialog = false
                          this.deactivateDialog = false
                      })
        }
    },
    computed: {
        ...mapState(['userInfo'])
    }
}
</script>
